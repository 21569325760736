import React from 'react'
import { useNavigate, useLocation, useParams } from 'react-router-dom'
import { useEventContext } from '../../../contexts/EventProvider'
import useScrollTopOnMount from '../../../hooks/useScrollTopOnMount'
import NavBar from '../../atoms/NavBar'
import BackButton from '../../atoms/nav-controls/BackButton'
import { HStack, Container, Spinner } from '@chakra-ui/react'
import KeepsakeCards from './atoms/KeepsakeCards'
import { useRoleContext } from '../../../contexts/RoleProvider'

const Keepsake = () => {
	useScrollTopOnMount()

	const { role } = useRoleContext()
	const { uuid } = useParams()
	const { event, isOwner } = useEventContext()

	const navigate = useNavigate()
	const location = useLocation()

	const handleClose = () =>
		navigate(location?.state?.backDestination || (role == 'creator' ? `/event/${uuid}` : `/e/${uuid}`))

	if (event) {
		return (
			<>
				<NavBar pos={isOwner ? 'absolute' : 'relative'}>
					<HStack spacing={['4%', '1rem']}>
						<BackButton darkmode={role != 'creator'} left onClick={handleClose} />
					</HStack>
				</NavBar>

				<Container maxW="container.xl" py="1rem" variant="main">
					<KeepsakeCards />
				</Container>
			</>
		)
	}
	return <Spinner thickness="4px" speed="0.45s" emptyColor="gray.200" color="link" size="md" />
}

Keepsake.propTypes = {}

export default Keepsake
